(function($){

    jQuery( document ).ready(function() {
        
        /******************
        COOKIE NOTICE
        ******************/
        // https://www.amityweb.co.uk/blog/javascript-notification-box-using-cookies-to-remember-close/
        
        if(getCookie('show_cookie_message') != 'no') {
            jQuery('#notification-bar').show();
        }
    
        jQuery('.notification-bar__btn').on('click', function() {
            jQuery('#notification-bar').animate({ opacity:0 }, 250);
            setCookie('show_cookie_message','no');
            return false;
        });
    
        function setCookie(cookie_name, value) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + (1));
            document.cookie = cookie_name + "=" + escape(value) + "; expires="+exdate.toUTCString() + "; path=/";
        }
        
        function getCookie(cookie_name) {
            if (document.cookie.length>0) {
                cookie_start = document.cookie.indexOf(cookie_name + "=");
                
                if (cookie_start != -1) {
                    cookie_start = cookie_start + cookie_name.length+1;
                    cookie_end = document.cookie.indexOf(";",cookie_start);
                    
                    if (cookie_end == -1) {
                        cookie_end = document.cookie.length;
                    }
                    return unescape(document.cookie.substring(cookie_start,cookie_end));
                }
            }
            return "";
        }
        
        jQuery(".header-menu").on('click', function(){
            // *this* refers to the current element
            jQuery('body').toggleClass('overflow');
        });
    });

    /**************************************/
    /* !Magnifying Glass Mobile           */
    /**************************************/

    $('#menu-item-3442').on('click', function() {
        $('.main-menu-search-item').toggleClass('main-menu-search-item--active');
    });
        
    /**************************************/
    /* !On header scroll                  */
    /**************************************/

    jQuery(window).scroll(function(){ 
      var $header = jQuery('.header');
      if (jQuery(this).scrollTop() > 10){      
        if(!$header.hasClass('test') && !$header.hasClass('header--is-sticky') ) {
            $header.addClass("test");
            $header.addClass("header--is-sticky");
            $header.next().css('margin-top','120px');
        }
      } 
      else {
        if($header.hasClass('test') && $header.hasClass('header--is-sticky') ) {
            $header.removeClass("test");
            $header.removeClass("header--is-sticky");
            $header.next().css('margin-top','0');
        }
      }
    });

    // Mobile menu
    $('.header-menu').on('click keypress', function(){
        $(this).toggleClass('open')
        $('.mobile_menu').toggleClass('active')
    })

    $('.search-button').on('click keypress', function(){
        $('.search-bar').toggleClass('active')
    })
    


    // Cookie functions
    function setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==" ") c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    function eraseCookie(name) {   
        document.cookie = name+"=; Max-Age=-99999999;";  
    }

    $(document).ready(function() {
        
    /**************************************/
    /* !Carousels                         */
    /**************************************/
    if ( $('.logos__carousel').length > 0 ) {
        var uspsSlideshow = tns({
            container: '.logos__carousel',
            mouseDrag:  true,
            slideBy:    1,
            autoplay:   true,
            speed:      1000,
            autoplayTimeout: 4000,
            controls:   false,
            nav:        false,
            loop:       true,
            edgePadding: 0,
            center:      false,
            autoplayButtonOutput: false,
            gutter:      50,
            autoWidth:   false,
            responsive: {
                0: {
                    items: 3,
                },
                600: {
                    items: 4,
                },
                1000: {
                    items: 6,
                }

            }
        });
    }
        
    /* !Image Row carousel */
    if ( $('.image-row__carousel').length > 0 ) {
        // https://github.com/ganlanyuan/tiny-slider/issues/154
        // For creating inline data attributes
            
        var imageRowCarousel = document.querySelectorAll('.image-row__carousel');
        
        imageRowCarousel.forEach((imageRowCarousel) => {
            var opt              = JSON.parse(imageRowCarousel.getAttribute('data-tiny-slider'));
            opt.container        = imageRowCarousel;
            opt.gutter           = 0;
            opt.mouseDrag        = true;
            opt.speed            = 500;
            opt.autoplayButtonOutput = false;
            var slider = tns(opt);
        });	
    }
    
    /**************************************/
    /* !Lightbox                          */
    /**************************************/
    
    //https://github.com/sachinchoolur/lightGallery
    
    if ( $('.lightbox-gallery').length > 0 ) {
        
        var lightboxes = document.querySelectorAll('.lightbox-gallery');
        
        lightboxes.forEach((lightboxes) => {
            lightGallery(lightboxes, {
                plugins: [lgThumbnail],
                speed: 500,
                selector: '.lightbox-gallery__link',
                
            });
        });
    }
    
    /**************************************/
    /* !Main Menu delay closing           */
    /**************************************/
    // https://stackoverflow.com/questions/3331353/transitions-on-the-css-display-property
    
    // We need to keep track of faded in elements so we can apply fade out later in CSS
    // document.addEventListener('animationstart', function (e) {
    //   if (e.animationName === 'fade-in') {
    //       e.target.classList.add('did-fade-in');
    //   }
    // });
    // 
    // document.addEventListener('animationend', function (e) {
    //   if (e.animationName === 'fade-out') {
    //       e.target.classList.remove('did-fade-in');
    //    }
    // });
});

})(jQuery);